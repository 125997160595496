<template>
	<div class="bg-fff padding-10 margin-t-10" style="height: calc( 100% - 30px);">
		<div class="font-size18 font-weight700 margin-b-10 ">修改密码</div>
		<div class="flex-column-center-center" style="width: 100%;height: 100%;">
			<el-form :model="ruleForm" status-icon :rules="rules" style="width: 472px;" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="手机号码" prop="pwd_phone">
					<el-input 
						autocomplete="off"  :placeholder="getUserInfo.phone"  disabled="disabled">
					</el-input>
				</el-form-item>
				
				<!-- <el-form-item label="原始密码" prop="pwd_one">
					<el-input type="password" placeholder="请输入原始密码" v-model="ruleForm.pwd_old" autocomplete="off"></el-input>
				</el-form-item> -->
				<el-form-item label="新密码" prop="pwd_new">
					<el-input v-model.trim="ruleForm.pwd_new" :type="passShow?'text':'password'" 
						autocomplete="off"  placeholder="请输入6-20位数字或字母的密码">
						<i slot="suffix" class="iconfont pointer" :class="passShow?'icon-eye':'icon-yanjing1'" @click="checkPassIcon(1)"></i>
					</el-input>
				</el-form-item>
				<el-form-item label="确认密码" prop="rePassword">
					<el-input v-model.trim="ruleForm.rePassword" :type="passShow1?'text':'password'"  maxlength='20'
						placeholder="请再次确认密码">
						<i slot="suffix" class="iconfont pointer" :class="passShow1?'icon-eye':'icon-yanjing1'" @click="checkPassIcon(2)"></i>
					</el-input>
				</el-form-item>
				<el-form-item label="填写验证码" prop="pwd_yanzheng" style="position: relative;">
					<div v-show="innerVisibles" style="position: absolute;bottom: 0px;z-index: 999; padding: 3px; background-color: #fff; left: 0;">
						<i  class="el-icon-circle-close" style="position: absolute;right: -20px;top: -20px;color: #0173FE;font-size:26px;" @click="closeYanzheng"></i>
						<changeGraphic @messageData = 'messageData'></changeGraphic>
					</div>
					<!-- <el-input v-model.trim="ruleForm.yanzheng"
						autocomplete="off"  placeholder="请输入验证码">
					</el-input> -->
					<el-input v-model="formData.Perfectregister" v-model.trim="ruleForm.yanzheng" autocomplete="off" placeholder="输入验证码">
						<template slot="append">
							<div  class="pointer" style="color: #0173fe;" v-if="subStatus"  @click="countDown">{{getYZ}}</div>
							<div v-if="!subStatus" style="color: #0173fe">{{resend}}{{getYZ}}{{miao}}</div>
						</template>
					</el-input>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')" style="background-color: #0173FE;width: 120px;height: 40px;border-radius: 2px !important;">提交</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters,
		mapActions
	} from "vuex";
	import changeGraphic from '@/components/changeGraphic';
	export default {
		data() {
			var validatePass2 = (rule, value, callback) => {
				  if (value === '') {
			         callback(new Error('请再次输入密码'))
			      } else if (value != this.ruleForm.pwd_new) {
			         callback(new Error('两次输入密码不一致!'))
			      } else {
			       	 callback()
			      }
			    };
			return {
				passShow: false,
				passShow1: false,
				ruleForm: {
					pwd_one: '',
					pwd_new: '',
					rePassword:'',
					yanzheng:''
				},
				getYZ: "发送验证码",
				miao: '',
				resend: '',
				subStatus:true,
				innerVisibles:false,
				msg:false,
				rules: {
					// pwd_one: [{
					// 	required: true,
					// 	message: '请输入原始密码',
					// 	trigger: 'change'
					// }],
					pwd_new: [{
						required: true,
						message: '请输入密码',
						trigger: 'change' 
					},{
						required: true,
						pattern: /^[A-Za-z0-9]{6,20}$/,
						message: '请输入6-20位数字或字母的密码',
						trigger: 'change'
					}],
					rePassword:[{
						required: true,
						message: '请再次确认密码',
						trigger: 'change' 
					},{ required: true, validator: validatePass2, trigger: 'blur' }],

					// pwd_yanzheng: [{
					// 	required: true,
					// 	message: '请输入短信验证码',
					// 	trigger: 'change'
					// }],
				}
			}
		},
		computed: {
			...mapGetters(["getThemeName", 'getUserInfo']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		components: {
			changeGraphic,
		},
		methods: {
			messageData(a) {
					if(a){
						var _this = this;
						setTimeout(function(){
							_this.innerVisibles = false;
							_this.msg = a
							_this.countDown();
							console.log('子传递值，已经变成true')
						}, 500);
					}
			 },
			//提交验证
			submitForm(formName) {
				console.log(this.ruleForm,"this.ruleForm");
				if(this.ruleForm.pwd_new == ""){
					this.tipsInfo('请输入新密码!');
					return;
				}
				if(this.ruleForm.pwd_new.length <6 || this.ruleForm.pwd_new.length >20){
					this.tipsInfo('新密码应设置为6-20位字母或数字');
					return;
				}
				if(this.ruleForm.rePassword == ""){
					this.tipsInfo('请再次输入密码！');
					return;
				}
				if(this.ruleForm.pwd_new != this.ruleForm.rePassword){
					this.tipsInfo('两次输入不一致！');
					return;
				}
				if(this.ruleForm.yanzheng == ""){
					this.tipsInfo('请输入短信验证码!');
					return;
				}
				this.$refs[formName].validate((valid) => {
					let that = this;
					if (valid) {
						this.ApiRequestPost("/api/mall/sys/profile/change-password-sms", {
							  token: that.ruleForm.yanzheng,
							  password: that.ruleForm.pwd_new
						}).then(res => {
							console.log('密码修改成功！')
							if(res.obj == true){
								setTimeout(function(){
									that.$router.push({path: '/login'})
								},1000)
								
							}
						}).catch(err => {
							
						})
					} else {
						// console.log('error submit!!');
						return false;
					}
				});
			},
			
			closeYanzheng(){
				this.innerVisibles = false;
			},
			
			
			
			//获取验证码
			countDown(){
				console.log('事件触发')
				// if( )
				console.log(this.ruleForm.pwd_new)
				if(this.msg == false){
					this.innerVisibles = true;
					return;
				}
				//获取验证码接口，不要删
				this.ApiRequestPost("/api/mall/sys/profile/change-password-sms-by-phone", {
				}).then(res => {
					console.log('验证码接口调用成功！',res)
					if(res.obj.status == true){
						console.log(res,"获取验证码成功")
						this.subStatus = false;
						this.innerVisibles = false;
							this.getYZ = 60;
							this.miao = "秒）";
							this.resend = '重发验证（';
							let time = setInterval(() => {
								this.getYZ--;
								if (this.getYZ == 0) {
									clearInterval(time);
									this.miao = "";
									this.resend = '';
									this.getYZ = "发送验证码";
									this.subStatus = true;
									this.msg = false
								}
							}, 1000);
					}else{
						this.tipsInfo('操作频繁，请稍等再试');
						this.msg = false
					}

					
				}).catch(err => {
				
				})
			},
			//密码可视化
			checkPassIcon(index) {
				if(index == 1){
					this.passShow = !this.passShow;
				}else{
				this.passShow1 = !this.passShow1;	
				}
				
			},
		}
	}
</script>

<style scoped lang="scss">
.passBox {
		position: relative;

		.icon-yanjing3,
		.icon-yanjing1 {
			position: absolute;
			right: 8px;
			top: 2px;
			color: #ccc;
		}

		.icon-yanjing1 {
			color: #000;
		}
		
	}
	/deep/ .el-input__validateIcon{
		display: none;
	}
</style>
